import React, { useState } from "react"

import CurrentLocationContext from "~context/currentLocation"

const CurrentLocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState('miamiBeach')

  return (    
    <CurrentLocationContext.Provider value={[currentLocation, setCurrentLocation]}>
      {children}
    </CurrentLocationContext.Provider>
  )
}

export default CurrentLocationProvider
