import React, { useState } from "react"

import EmailContext from "~context/email"


const EmailProvider = ({ children }) => {
  const [emailAddress, setEmailAddress] = useState(false)

  return (    
    <EmailContext.Provider value={[emailAddress, setEmailAddress]}>
      {children}
    </EmailContext.Provider>
  )
}

export default EmailProvider
